import React from "react";
import { Breadcrumb, Alert, message } from "antd";
import { withRouter, Link } from "react-router-dom";
import query from "query-string";
import { Helmet } from "react-helmet";

import withSubscriptions from "common/withSubscriptions";
import { fetchAndSetLinkedTasks, getLabel, assignTaskToUser } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { fetchActivityItemsForRequest } from "common/shared";
import { getSimpleLabel } from "common/labels";
import { isAuthorised } from "common/permissions";
import { getTaskRevisionName } from "common/naming";

import TaskSummary from "./TaskSummary/TaskSummary";
import TaskSidebar from "./TaskSidebar/TaskSidebar";
import TaskRevisions from "./TaskRevisions/TaskRevisions";
import Attachments from "Attachments/Attachments";
import Activity from "Activity/Activity";
import Subtasks from "./Subtasks/Subtasks";
import TaskActions from "./TaskActions/TaskActions";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import Audit from "Audit/Audit";
import DocumentRegisterTab from "./DocumentRegisterTab/DocumentRegisterTab";
import CreateTaskRevisionModal from "CreateTaskRevisionModal/CreateTaskRevisionModal";
import CopyLinkButton from "CopyLinkButton/CopyLinkButton";
import TaskTimesheets from "./TaskTimesheets/TaskTimesheets";
import TaskBudget from "./TaskBudget/TaskBudget";
import TaskQuotes from "./TaskQuotes/TaskQuotes";
import ClientCommunicationCard from "ClientCommunicationCard/ClientCommunicationCard";
import Tabs from "reusableComponents/Tabs/Tabs";

import "./TaskDetailsPage.scss";

export class TaskDetailsPage extends React.Component {
  _isMounted = false;
  lastLinkedTasks = null;

  state = {
    task: null,
    users: null,
    error: null,
    filterValue: "",
    isCreateTaskRevisionModalOpen: false,
    activeTab: "revisions",
    linkedTasksDetails: [],
    isSendPublicUploadEmailVisible: false,
    numberToRefreshAttachments: 0,
    activityItemsByRequest: undefined,
    attachmentsPrefix: "",
  };

  async componentDidMount() {
    this._isMounted = true;

    this.lastLinkedTasks = JSON.stringify(this.props.task.linkedTasks);
    fetchAndSetLinkedTasks.call(this);
    this.fetchActivityDetailsForRequests();
    if (this.props.setBoxedLayout) {
      this.props.setBoxedLayout(false);
    }

    const { organisationDetails } = this.props;

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: this.props.task.id,
          projectId: this.props.task.projectId,
          fileId: "nothing",
          clientId: this.props.task.clientId,
          page: "TASK_DETAILS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    const queryString = query.parse(this.props.location.search);
    const activeTab = queryString.tab;

    if (activeTab) {
      this.setState({ activeTab });
    } else if (!organisationDetails.usesRevisions) {
      this.setState({ activeTab: "attachments" });
    }

    this.runSanityCheck();
  }

  componentWillUnmount() {
    if (this.props.setBoxedLayout) {
      this.props.setBoxedLayout(true);
    }
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.task.linkedTasks) !== this.lastLinkedTasks) {
      this.lastLinkedTasks = JSON.stringify(this.props.task.linkedTasks);
      fetchAndSetLinkedTasks.call(this);
    }

    if (
      JSON.stringify(this.props.task.requestIds || []) !== JSON.stringify(prevProps.task.requestIds || []) ||
      this.props.task.itemSubscription !== prevProps.task.itemSubscription
    ) {
      this.fetchActivityDetailsForRequests();
    }
  }

  runSanityCheck = async () => {
    await this.checkIfThereAreNoTaskRevisions();
    await this.checkIfLatestTaskRevisisionIsReadOnlyWhenItShouldntBe();
    await this.checkIfLatestTaskRevisisionIsNotReadOnlyWhenItShouldBe();
    await this.checkIfTaskIsReadOnlyWhenItShouldNotBe();
    await this.checkLatestTaskRevisionAuthor();
  };

  checkLatestTaskRevisionAuthor = async () => {
    const { task, users, organisationDetails } = this.props;
    if (task.isFinished || task.isArchived) {
      return;
    }

    let latestTaskRevision = task.revisions.items.slice(-1)[0];

    if (!latestTaskRevision || latestTaskRevision.isReadOnly || latestTaskRevision.reviewAcceptDate) {
      return;
    }

    if (latestTaskRevision.author === task.assignedTo) {
      return;
    }

    let userDetails = users.find((x) => x.id === task.assignedTo);

    await assignTaskToUser({
      task,
      user: userDetails,
      organisationDetails,
      includeNotification: false,
    });
  };

  checkIfThereAreNoTaskRevisions = async () => {
    const { task, apiUser } = this.props;
    const latestTaskRevision = task.revisions.items.slice(-1)[0];

    if (latestTaskRevision) {
      return;
    }

    const newReview = (
      await callGraphQLSimple({
        message: "Failed to create review",
        mutation: "createReview",
        variables: {
          input: {
            organisation: apiUser.organisation,
            reviewThread: [],
            approvedItems: [],
          },
        },
      })
    ).data.createReview;

    let newTaskRevisionStatus =
      window.organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions &&
      window.organisationDetails?.fileStatuses
        ? window.organisationDetails?.fileStatuses[0].name.toUpperCase().split(" ").join("_")
        : undefined;

    await callGraphQLSimple({
      message: `Failed to create ${getSimpleLabel("task revision")}`,
      queryCustom: "createTaskRevision",
      variables: {
        input: {
          taskId: task.id,
          name: await getTaskRevisionName({
            organisation: task.organisation,
            task: task,
            newStatus: window.organisationDetails?.fileStatuses
              ? window.organisationDetails?.fileStatuses[0].name
              : undefined,
          }),
          description: "Initial Issue",
          organisation: apiUser.organisation,
          author: task.assignedTo,
          reviewId: !newReview ? "nothing" : newReview.id,
          status: newTaskRevisionStatus,
        },
      },
    });

    this.refreshTaskDetails();
  };

  checkIfLatestTaskRevisisionIsReadOnlyWhenItShouldntBe = async () => {
    const { task } = this.props;
    const latestTaskRevision = task.revisions.items.slice(-1)[0];

    if (
      !latestTaskRevision ||
      !latestTaskRevision.isReadOnly ||
      latestTaskRevision.reviewAcceptDate ||
      task.isArchived ||
      task.isFinished
    ) {
      return;
    }

    await callGraphQLSimple({
      queryCustom: "updateTaskRevision",
      displayError: false,
      variables: {
        input: {
          id: latestTaskRevision.id,
          isReadOnly: false,
        },
      },
    });

    await this.refreshTaskDetails();
  };

  checkIfTaskIsReadOnlyWhenItShouldNotBe = async () => {
    const { task } = this.props;

    if (task.isArchived || task.isFinished || !task.isReadOnly) {
      return;
    }

    await callGraphQLSimple({
      queryName: "updateTask",
      message: `Failed to update ${getSimpleLabel(
        "task"
      )}'s read-only flag: it was incorrectly marked as read-only still is`,
      variables: {
        input: {
          id: task.id,
          isReadOnly: false,
        },
      },
    });

    message.success(
      `${getSimpleLabel("Task")} had been incorrectly marked as read-only and has been fixed automatically.`
    );
  };

  checkIfLatestTaskRevisisionIsNotReadOnlyWhenItShouldBe = async () => {
    const { task } = this.props;
    const latestTaskRevision = task.revisions.items.slice(-1)[0];

    if (!latestTaskRevision || latestTaskRevision.isReadOnly) {
      return;
    }

    if (task.isFinished || task.isArchived || latestTaskRevision.reviewAcceptDate) {
      await callGraphQLSimple({
        queryCustom: "updateTaskRevision",
        displayError: false,
        variables: {
          input: {
            id: latestTaskRevision.id,
            isReadOnly: false,
          },
        },
      });

      await this.refreshTaskDetails();
    }
  };

  fetchActivityDetailsForRequests = async () => {
    const { task } = this.props;
    if (!task.requestIds || task.requestIds.length === 0) {
      this.setState({
        activityItemsByRequest: {},
      });
      return;
    }

    const activityDetailsForRequests = await Promise.all(task.requestIds.map(fetchActivityItemsForRequest));
    let activityItemsByRequest = {};
    for (let activityDetailsForRequest of activityDetailsForRequests) {
      activityItemsByRequest[activityDetailsForRequest.requestId] = activityDetailsForRequest.activityItems;
    }

    this.setState({
      activityItemsByRequest,
    });
  };

  refreshTaskDetails = async () => {
    const { task } = this.props;
    await callGraphQLSimple({
      queryCustom: "updateTask",
      variables: {
        input: {
          id: task.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      },
    });
  };

  displayTabs = () => {
    const { activeTab, linkedTasksDetails, activityItemsByRequest } = this.state;
    const { task, users, apiUser, user, organisationDetails, windowWidth, isModal, setProps, context } = this.props;
    const basePath = `/tasks/${task.id}`;

    let tabs = [];
    if (organisationDetails.usesRevisions) {
      tabs.push({
        id: "revisions",
        title: getSimpleLabel("Revisions-task-details-page-tab"),
        content: (
          <TaskRevisions
            user={user}
            apiUser={apiUser}
            users={users}
            task={task}
            organisationDetails={organisationDetails}
            activityItemsByRequest={activityItemsByRequest}
            openCreateTaskRevisionModal={() =>
              this.setState({
                isCreateTaskRevisionModalOpen: true,
              })
            }
            linkedTasksDetails={linkedTasksDetails}
            windowWidth={windowWidth}
          />
        ),
      });
    }

    if (organisationDetails.settings?.general?.usesDocumentRegister) {
      tabs.push({
        id: "document-register",
        title: "Document register",
        content: <DocumentRegisterTab apiUser={apiUser} task={task} organisationDetails={organisationDetails} />,
      });
    }

    if (
      (organisationDetails.settings?.quote?.usesQuotes || organisationDetails.settings?.invoice?.usesInvoices) &&
      isAuthorised(["TASK.VIEW_FINANCIALS"])
    ) {
      tabs.push({
        id: "financials",
        title: getLabel({
          id: "financials-tab",
          defaultValue: "Financials",
        }),
        content: (
          <>
            {(organisationDetails.settings?.quote?.usesQuotes ||
              organisationDetails.settings?.timesheet?.usesTimesheets) &&
              isAuthorised(["TASK.VIEW_BUDGET_BAR"]) && <TaskBudget task={task} />}
            {organisationDetails.settings?.quote?.usesQuotes && (
              <TaskQuotes task={task} apiUser={apiUser} refreshTaskDetails={this.refreshTaskDetails} />
            )}
          </>
        ),
      });
    }

    if (organisationDetails.settings?.timesheet?.usesTimesheets) {
      tabs.push({
        id: "timesheets",
        title: "Timesheets",
        content: <TaskTimesheets task={task} />,
      });
    }

    tabs.push({
      id: "subtasks",
      title: "Subtasks",
      content: <Subtasks task={task} apiUser={apiUser} setProps={setProps} context={context} />,
    });

    tabs.push({
      id: "attachments",
      title: getSimpleLabel("task-details-attachments-tab"),
      content: (
        <Attachments
          task={task}
          apiUser={apiUser}
          key={`${this.state.numberToRefreshAttachments}-${this.state.activeTab}`}
          onPrefixChange={(prefix) => this.setState({ attachmentsPrefix: prefix })}
        />
      ),
    });

    if (organisationDetails.settings?.task?.hideRawCloudStorageTab !== true) {
      tabs.push({
        id: "raw-cloud-storage",
        title: "Raw cloud storage",
        content: (
          <Attachments
            task={task}
            apiUser={apiUser}
            key={`${this.state.numberToRefreshAttachments}-${this.state.activeTab}`}
            defaultPath={`projects/${task.projectId}/tasks/${task.id}`}
            readOnly
          />
        ),
      });
    }

    tabs.push({
      id: "activity",
      title: "Activity",
      content: <Activity parentRecord={task} parentId={task.id} parentLabel="Task" apiUser={apiUser} />,
    });

    tabs.push({
      id: "actions",
      title: "Actions",
      content: (
        <TaskActions
          task={task}
          apiUser={apiUser}
          organisationDetails={organisationDetails}
          onDelete={this.props.onDelete}
        />
      ),
    });

    if (window.featureShowAudit) {
      tabs.push({
        id: "audit",
        title: "Audit",
        content: (
          <Audit
            auditItems={task.auditItems.items}
            apiUser={apiUser}
            users={users}
            organisationDetails={organisationDetails}
          />
        ),
      });
    }

    return (
      <Tabs
        className="task-main-tabs"
        items={tabs}
        activeTab={activeTab}
        onTabClick={(tabKey) => {
          if (!isModal) {
            this.props.history.push(`${basePath}?tab=${tabKey}`);
          }
          this.setState({ activeTab: tabKey });
        }}
      />
    );
  };

  render() {
    const { isCreateTaskRevisionModalOpen, activityItemsByRequest } = this.state;
    const { task, users, apiUser, projects, user, organisationDetails, windowWidth, clients } = this.props;

    const project = projects.find((x) => x.id === task.projectId);
    if (!task || !project) {
      return null;
    }

    const client = (task.client = clients.find((x) => x.id === task.clientId));

    let layout = {};

    if (windowWidth > 850) {
      layout.sidebarWidth = "300px";
    } else {
      layout.sidebarWidth = 0;
    }

    const taskUrl = `${window.location.origin}/tasks/${task.id}`;

    return (
      <div
        className="task-details-page"
        onClick={(e) => {
          if (this.props.onClick) {
            this.props.onClick(e);
          }
        }}
      >
        <Helmet>
          <meta
            name="description"
            content={`${task.client?.name}: ${project?.title} ${task.title} - ${task.description}`}
          />
          <title>{task.title}</title>
        </Helmet>
        <div className="ant-breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/clients/${task.client?.id}`}>{task.client?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/projects/${task.projectId}`}>
                {organisationDetails.settings?.task?.automaticallyCreateProject
                  ? getSimpleLabel("Project")
                  : project?.title}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <div data-cy="task-breadcrumb">
                <TaskIdTag task={task} />
                <CopyLinkButton url={taskUrl} />
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="page-content">
          <div className="main-page-content">
            {task.isExternalReference ? (
              <Alert
                showIcon
                message={`This ${getLabel({
                  id: "task",
                  defaultValue: "task",
                })} is an external reference.`}
                type="info"
              />
            ) : null}
            {task.isArchived ? (
              <Alert
                showIcon
                message={`This ${getLabel({
                  id: "task",
                  defaultValue: "task",
                })} is archived. While in this state, you cannot make any changes to it.`}
                type="info"
              />
            ) : null}
            {task.isFinished ? (
              <Alert
                showIcon
                message={`This ${getLabel({
                  id: "task",
                  defaultValue: "task",
                })} is finished. While in this state, you cannot make any changes to it.`}
                type="info"
              />
            ) : null}

            <div className="summary-and-client-communication">
              <TaskSummary task={task} user={user} users={users} apiUser={apiUser} />
              <ClientCommunicationCard
                task={task}
                project={project}
                client={client}
                apiUser={apiUser}
                users={users}
                organisationDetails={organisationDetails}
                activityItemsByRequest={activityItemsByRequest}
                attachmentsPrefix={this.state.attachmentsPrefix}
              />
              {layout.sidebarWidth === 0 && (
                <div style={{ marginTop: "-1rem" }}>
                  <TaskSidebar
                    task={task}
                    user={user}
                    splitLayout={windowWidth > 500}
                    refreshTaskDetails={this.refreshTaskDetails}
                    refreshAttachments={() => {
                      this.setState({
                        numberToRefreshAttachments: this.state.numberToRefreshAttachments + 1,
                      });
                    }}
                  />
                </div>
              )}
            </div>

            {this.displayTabs()}
          </div>

          {layout.sidebarWidth !== 0 && (
            <div style={{ width: layout.sidebarWidth, flexShrink: 0 }}>
              <TaskSidebar
                task={task}
                user={user}
                refreshTaskDetails={this.refreshTaskDetails}
                refreshAttachments={() => {
                  this.setState({
                    numberToRefreshAttachments: this.state.numberToRefreshAttachments + 1,
                  });
                }}
                activityItemsByRequest={this.state.activityItemsByRequest}
              />
            </div>
          )}
        </div>
        {isCreateTaskRevisionModalOpen && (
          <CreateTaskRevisionModal
            apiUser={apiUser}
            task={task}
            project={project}
            client={client}
            users={users}
            organisationDetails={organisationDetails}
            onClose={() => this.setState({ isCreateTaskRevisionModalOpen: false })}
          />
        )}
      </div>
    );
  }
}

export const TaskDetailsPageWithSubscriptions = withSubscriptions({
  Component: TaskDetailsPage,
  subscriptions: ["organisationDetails", "users", "task", "tasks", "windowWidth", "windowHeight", "clients"],
});

export default withRouter(TaskDetailsPageWithSubscriptions);
